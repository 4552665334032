<template>
  <div class="visit-history">
    <filter-manager
      ref="filterComponent"
      v-model="filters"
      class="mb-4"
      :display-advanced="false"
      :filters="filtersConfig"
      @academicYear="academicYearChangeHandler"
      @search="onSearchHandler"
    />
    <custom-separator class="my-4" text="Resultado de Registro de Visitas" />
    <custom-result-not-found v-if="results.length === 0" />
    <template v-else>
      <result-row
        v-for="(row, idx) of results"
        :key="`result-${idx}`"
        class="mb-3"
        :data="row"
        @on-open-close="openCloseResultHandler($event, idx)"
      />
      <pagination
        v-if="pagination.totalItemCount > pagination.pageSize"
        :data="pagination"
        show-text
        @pagination-go-page="goToPage"
      />
    </template>
  </div>
</template>

<script>
import Pagination from 'pagination';
import FilterManager from 'FilterManager';
import CustomSeparator from 'custom-separator';
import CustomResultNotFound from 'custom-result-not-found';
import ResultRow from '@/views/students/visits-history/components/ResultRow.vue';
import {
  getAcademicYearVisits,
  getThrpyVisits,
  getVisits,
} from '@/services/api/visitLog.api';

export default {
  name: 'VisitLogHistory',
  props: { studentSieId: { type: [String, Number], default: null } },
  components: {
    FilterManager,
    Pagination,
    CustomSeparator,
    CustomResultNotFound,
    ResultRow,
  },
  data: () => ({
    pagination: {
      currentPage: 1,
      totalItemCount: 0,
      pageCount: 1,
      count: 0,
      pageSize: 10,
    },
    filters: {},
    filterOptions: {
      academicYears: [],
      therapies: [],
    },
    results: [],
  }),
  computed: {
    filtersConfig() {
      return [
        {
          name: 'academicYear',
          component: 'CustomDropDown',
          options: this.filterOptions.academicYears,
          placeholder: 'Seleccione Año Académico',
          fieldName: 'name',
          fieldKey: 'id',
          clearable: true,
          class: 'col-12 col-md-4',
          initialValue: this.filters.academicYear,
        },
        {
          name: 'therapy',
          component: 'CustomDropDown',
          options: this.filterOptions.therapies,
          disabled: this.filterOptions.therapies.length == 0,
          placeholder: 'Seleccione Terapia',
          fieldName: 'name',
          fieldKey: 'id',
          class: 'col-12 col-md-4',
          clearable: true,
        },
        {
          name: 'referralNumber',
          component: 'CustomInput',
          icon: 'fa fa-search color-orange',
          leftIcon: true,
          placeholder: 'Escriba Número de Referido',
          class: 'col-12 col-md-4',
        },
      ];
    },
  },
  async created() {
    await Promise.all([this.initFilters(), this.onSearchHandler()]);
  },
  methods: {
    async initFilters() {
      const { data } = await getAcademicYearVisits(this.studentSieId);
      this.filterOptions.academicYears = data;
    },
    async academicYearChangeHandler(academicYear) {
      this.filterOptions = {
        ...this.filterOptions,
        therapies: [],
      };
      this.filters = {
        ...this.filters,
        therapy: null,
      };
      if (academicYear == null) return;
      this.filterOptions.therapies = [];
      const model = this.filters;
      model.therapy = null;
      const { data } = await getThrpyVisits(this.studentSieId, academicYear.id);
      this.filterOptions.therapies = data;
    },
    async goToPage(page) {
      this.pagination.currentPage = page;
      await this.onSearchHandler();
    },
    async onSearchHandler() {
      const model = { ...this.filters };
      const params = {
        studentSieId: this.studentSieId,
        academicYearId: model.academicYear?.id,
        disciplineTypeId: model.therapy?.id,
        referralNumber: model.referralNumber,
      };
      const { data: response } = await getVisits(
        params,
        this.pagination.currentPage,
        this.pagination.pageSize
      );
      this.results = [...response.data];
      this.pagination = {
        ...this.pagination,
        currentPage: response.currentPage,
        count: response.count,
        pageCount: response.pageCount,
        totalItemCount: response.totalItemCount,
      };
    },
    openCloseResultHandler(value, idx) {
      this.results = this.results.map((r, i) => ({
        ...r,
        isOpen: i === idx ? !value : false,
      }));
    },
  },
};
</script>
