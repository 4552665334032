<template>
  <div v-if="studentSieId">
    <page-header
      has-return
      :page="{
        icon: 'fas fa-calendar-day',
        title: 'Registro de Visitas',
        routes: ['Inicio', 'Registro de Visitas'],
      }"
    />
    <visit-log-history :student-sie-id="Number(studentSieId)" />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import VisitLogHistory from '@/views/students/visits-history/components/VisitLogHistory.vue';

export default {
  name: 'VisitsHisotry',
  components: { PageHeader, VisitLogHistory },
  props: { studentSieId: { type: [String, Number], default: null } },
};
</script>
