import { http } from '@/core/api/';

const URL = 'api/parent/registryvisit';

export const getAcademicYearVisits = (studentSieId) =>
  http.get(`${URL}/academicyears/${studentSieId}`);

export const getThrpyVisits = (studentSieId, academicYearId) =>
  http.get(`${URL}/thrpyvisits/${studentSieId}/${academicYearId}`);

export const getVisits = (params, pageIndex, pageSize) =>
  http.get(`${URL}/visits/${pageIndex}/${pageSize}`, { params });

export const getVisitsHistory = (params, pageIndex, pageSize) =>
  http.post(`${URL}/visitsHistory/${pageIndex}/${pageSize}`, params);
