<template>
  <div class="history">
    <icon-view
      :has-border="false"
      icon="fas fa-history color-orange"
      icon-size="1rem"
      title="Historial de Visitas"
      variant="custom-icon-view"
    />

    <div
      v-for="(visit, idx) of visits"
      :key="`visit-${idx}`"
      class="pt-3 px-3 pb-2 bg-gray-3 rounded mb-3"
    >
      <detail-generator :details="mappedVisit(visit)" />
      <div class="rounded bg-secondary-9 p-3 mt-3">
        <span class="d-block mb-2 color-dark-gray font-weight-bold">
          Informaci&oacute;n Adicional de la Visita
        </span>
        <detail-generator
          :details="additionalInfo(visit)"
          wrapper-class="mx-n3"
        />
      </div>
    </div>
    <pagination
      v-if="pagination.totalItemCount > defaultPageSize"
      :data="pagination"
      show-text
      @pagination-go-page="goToPage"
    />
  </div>
</template>

<script>
import IconView from 'icon-view';
import Pagination from 'pagination';
import moment from 'moment';
import DetailGenerator from '@/components/common/DetailGenerator.vue';
import filterName from '@/utils/constants/filterName';
import { getVisitsHistory } from '@/services/api/visitLog.api';
import visitType from '@/utils/constants/visitTypes';
import { DoesntApply } from '@/utils/filters';

export default {
  name: 'VisitHistory',
  components: {
    IconView,
    DetailGenerator,
    Pagination,
  },
  props: {
    visitId: {
      type: Number,
      required: true,
    },
    disciplineTypeId: {
      type: Number,
      default: () => null,
    },
    defaultPageSize: {
      type: Number,
      default: () => 5,
    },
  },
  data: () => ({
    pagination: {
      currentPage: 1,
      totalItemCount: 0,
      pageCount: 1,
      count: 0,
    },
    visits: [],
  }),
  computed: {
    mappedVisit() {
      return (row) => {
        let visit = [
          {
            label: 'Fecha de Visita:',
            data: row.date,
            class: 'col-12 col-md',
            filters: [filterName.date],
          },
          {
            label: 'Hora:',
            data: `${this.formatHour(row.timeStart)} - ${this.formatHour(
              row.timeEnd
            )}`,
            class: 'col-12 col-md',
          },
          {
            label: 'Tipo de Visita:',
            data: row.visitTypeName,
            class: 'col-12 col-md',
          },
        ];
        switch (row.visitTypeId) {
          case visitType.Ausencia_Ofrecimiento:
          case visitType.Visita_Reposicion:
            visit.push(
              ...[
                {
                  label: 'Fecha a Reponer:',
                  data: row.repositionDate,
                  class: 'col-12 col-md',
                  filters: [filterName.date],
                },
              ]
            );
            break;
          case visitType.Ausencia_Ofrecimiento_AñoAnterior:
          case visitType.Ausencia_Ofrecimiento_Reposicion_AñoAnterior:
          case visitType.VisitaReposicion_AñoAnterior:
            visit.push(
              ...[
                {
                  label: 'Año Anterior:',
                  data: row.lastYear,
                  class: 'col-12 col-md',
                },
              ]
            );
            break;
        }
        return [{ info: visit }];
      };
    },
    additionalInfo() {
      return (row) => {
        let info = [
          {
            label: 'Comentario de la Visita:',
            data: DoesntApply(row.additionalInfo || row.visitAbsenceComments),
            class: 'col-12 col-md-6',
          },
          {
            label: 'Practicante/Interno:',
            data: DoesntApply(row.practicioners.join(', ')),
            class: 'col-12 col-md-6',
          },
        ];
        switch (row.visitTypeId) {
          case visitType.Ausencia:
          case visitType.Ausencia_Ofrecimiento:
            info.push(
              ...[
                {
                  label: 'Justificación:',
                  data: DoesntApply(row.visitAbsenceReason),
                  class: 'col-12 mt-md-2',
                },
              ]
            );
            break;
        }
        return [{ info }];
      };
    },
  },
  async created() {
    await this.getHistory();
  },
  methods: {
    formatHour({ value }) {
      return moment({
        hour: value.hours,
        minute: value.minutes,
        second: value.seconds,
      }).format('hh:mm a');
    },
    async goToPage(page) {
      this.pagination.currentPage = page;
      await this.getHistory();
    },
    async getHistory() {
      const params = {
        id: this.visitId,
        disciplineTypeId: this.disciplineTypeId,
      };
      const { data: res } = await getVisitsHistory(
        params,
        this.pagination.currentPage,
        this.defaultPageSize
      );
      this.visits = [...res.data];
      this.pagination = {
        currentPage: res.currentPage,
        count: res.count,
        pageCount: res.pageCount,
        totalItemCount: res.totalItemCount,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

.history ::v-deep {
  .custom-icon-view {
    margin-bottom: 0.5rem;
    .dot {
      box-shadow: none !important;
      margin-right: 0.5rem !important;
      .title-icon {
        margin: 0 !important;
      }
    }
    .font-16.color-gray-7 {
      font-size: 1rem !important;
      color: map-get($colors, 'dark-gray') !important;
    }
  }
}
</style>
