export default Object.freeze({
  Visita_Regular: 1,
  Visita_Reposicion: 2,
  Ausencia: 3,
  Ausencia_Ofrecimiento: 4,
  Visita_Admision: 5,
  Visita_Plan: 6,
  Compensatorio_Reposición: 7,
  Compensatorio_Regresión: 8,
  VisitaReposicion_AñoAnterior: 9,
  Ausencia_Ofrecimiento_AñoAnterior: 10,
  Compensatorio_Reposicion_Ausencia: 11,
  Visita_Paquete_Plan: 12,
  Visita_Ciberterapia: 13,
  Visita_Presencial_Durante_Emergencia_Covid19: 14,
  Visita_Presencial_Reposicion_Ano_Actual_Covid19: 15,
  Ciberterapia_Reposicion_Ano_Actual: 16,
  Ciberterapia_Reposicion_Ano_Anterior: 17,
  Visita_Presencial_Reposicion_Ano_Anterior_Covid19: 18,
  Ausencia_Ofrecimiento_Reposicion_AñoAnterior: 19,
  Telepractica_Regresion: 20,
  Presencial_Regresion: 21,
  Ausencia_Especialista: 22,
  Telepractica_Reposicion: 23,
  Presencial_Reposicion: 24,
  Ausencia_Ofrecimiento_Regresion: 25,
  Ausencia_Ofrecimiento_Reposicion: 26,
});
