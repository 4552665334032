<template>
  <custom-callout
    class-main="visit-log rounded"
    :controlled="true"
    :opened="data.isOpen"
    @click:open="$emit('on-open-close', data.isOpen)"
  >
    <div class="row py-2 mx-0">
      <div
        class="col-12 col-md-2 mb-6 mb-md-0 d-flex justify-content-between align-items-center d-md-block border-right"
      >
        <div class="mt-2 mt-md-0 w-100">
          <div
            class="px-4 py-2 rounded-pill text-center font-14 font-weight-bold ml-md-n3 fit-md-content mt-1 mb-2"
            :class="getPillBkgClass"
          >
            {{ data.status | capitalize }}
          </div>
          <div class="row mx-0 mx-md-n2">
            <div class="mt-3 mb-3 py-1">
              <span class="data-title color-black">
                {{ data.academicYearDescription }}
              </span>
              <span class="font-weight-bold">
                {{ data.referralNumber }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col mt-2 mt-md-0">
        <div class="row">
          <div class="col-12 col-md-4 mb-3">
            <span class="data-title">Servicio:</span>
            {{ data.disciplineName }}
          </div>
          <div class="col-12 col-md-4 mb-3">
            <span class="data-title">Especialista de Servicio:</span>
            {{ data.specialistName | capitalize }}
          </div>
          <div class="col-12 col-md-4 mb-3">
            <span class="data-title">Lugar de Servicio:</span>
            {{ data.locationName | capitalize }}
          </div>
          <div class="col-12 col-md-4 mb-3">
            <span class="data-title">Fecha desde:</span>
            {{ dateFormat(data.effectiveDateFrom) }}
          </div>
          <div class="col-12 col-md-4 mb-3">
            <span class="data-title">Fecha hasta:</span>
            {{ dateFormat(data.effectiveDateTo) }}
          </div>
        </div>
      </div>
    </div>
    <template #edition>
      <div class="bg-gray p-2 mt-1">
        <div class="bg-white rounded p-3">
          <visit-history
            :discipline-type-id="data.disciplineTypeId"
            :visit-id="data.visitHeaderId"
          />
        </div>
      </div>
    </template>
  </custom-callout>
</template>

<script>
import CustomCallout from 'custom-callout';
import VisitHistory from '@/views/students/visits-history/components/VisitHistory.vue';
import { capitalize } from '@/utils/filters';
import moment from 'moment';

export default {
  name: 'ResultRow',
  components: { CustomCallout, VisitHistory },
  filters: { capitalize },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    dateFormat: function(date) {
      if (date) {
        return moment(date)
          .locale('es')
          .format('DD/MMM/YYYY');
      }
      return '-';
    },
  },
  computed: {
    getPillBkgClass() {
      let className = 'bg-green-light';

      if (this.data.status == 'No activo') {
        className = 'bg-cosmos';
      }
      return className;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_colors.scss';
.visit-log {
  .data-title {
    display: block;
  }
  @media only screen and (min-width: 768px) {
    .fit-md-content {
      width: fit-content;
    }
  }
}
</style>
